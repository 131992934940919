import React, { useContext, Fragment } from 'react';

import {
  Caption,
  AspectRatioBox,
  Section,
  Panel,
  Grid,
  GridItem,
  VisibleAbove,
  Curtain,
  VisibleBelow,
  Button,
  Centered,
} from '@sdflc/ui';

import { SubscribeForm } from '../forms';
import { HomeLayout, ContactUsBlock, FoodMenu, BusinessHours, Uppercase, PageLoading, Logo } from '../components';
import { colors, siteInfo } from '../config';
import { withProviders } from '../hoc';
import { SquareUpContext } from '../contexts';
import { useDateTime } from '../hooks';

const homeBgs = [
  `${siteInfo.assetsBaserUrl}/jetana_home_page_006.jpg`,
  `${siteInfo.assetsBaserUrl}/jetana_home_page_004.png`,
  `${siteInfo.assetsBaserUrl}/jetana_home_page_001.jpg`,
  `${siteInfo.assetsBaserUrl}/jetana_home_page_002.jpg`,
  `${siteInfo.assetsBaserUrl}/jetana_home_page_003.jpg`,
  `${siteInfo.assetsBaserUrl}/jetana_home_page_005.png?v=1`,

  // `${siteInfo.assetsBaserUrl}/home-bg-001.jpg`,
  // `${siteInfo.assetsBaserUrl}/home-bg-002.jpg`,
  // `${siteInfo.assetsBaserUrl}/home-bg-003.jpg`,
  // `${siteInfo.assetsBaserUrl}/home-bg-004.jpg`,
];

const snowImg = `${siteInfo.assetsBaserUrl}/snow_001.png`;
const christmasImg = `${siteInfo.assetsBaserUrl}/christmas_lights_001.png`;

const IndexPage = () => {
  const { dateTime } = useDateTime();
  const { menu, locationDetails, loadingData, firstTimeLoading } = useContext(SquareUpContext);

  if (firstTimeLoading) {
    return <PageLoading />;
  }

  const status = `Currently ${dateTime.isOpen ? 'Open' : 'Closed'}`;

  return (
    <Fragment>
      <HomeLayout
        title={siteInfo.title}
        locationDetails={locationDetails}
        isLoading={loadingData}
        firstTimeLoading={firstTimeLoading}
      >
        <Panel backgroundColor={colors.red} padding={'0px'}>
          <ContactUsBlock header locationDetails={locationDetails} isLoading={loadingData} />
          <AspectRatioBox
            ratio='FREE'
            minHeight='100vh'
            centered
            backgroundColor='transparent'
            backgroundImage={homeBgs[0]}
          >
            {/* <div style={{ position: 'absolute', top: '10%', left: '0', width: '100%' }}>
              <Image src={christmasImg} width='100%' />
            </div> */}
            <Panel padding='20px' width='90%' zIndex={2}>
              <div style={{ textShadow: '0 0 15px #111' }}>
                <Centered>
                  <Panel pb={'30px'}>
                    <Logo square darkBg size='medium' />
                  </Panel>
                  <Caption level={1} noMargin textAlign='center'>
                    {locationDetails.name}
                  </Caption>
                  <div
                    style={{
                      paddingTop: '40px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      placeContent: 'center',
                      width: 'auto',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Button
                      variant='info'
                      dimension='large'
                      name='Order Online'
                      onClick={() => {
                        location.href = '/order-online';
                      }}
                      margin={1}
                    />
                    <Button
                      variant='secondary'
                      dimension='large'
                      name='Arena Schedule'
                      onClick={() => {
                        location.href = '/arena-schedule';
                      }}
                      margin={1}
                    />
                  </div>
                </Centered>
              </div>
            </Panel>
          </AspectRatioBox>
          <Section dimension='fluid' variant='default'>
            {/* <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', transform: 'translateY(-33%)' }}>
              <Image src={snowImg} width='100%' />
            </div> */}
            <Panel margin='0 auto' color={colors.white} padding='20px' pt={['0', '0', '0', '60px']} width='100%'>
              <Grid>
                <GridItem width={['100%', '100%', '30%', '30%']}>
                  <VisibleAbove breakpoint='sm'>
                    <Caption level={3} color={colors.white}>
                      Business
                      <br />
                      Hours
                    </Caption>
                    {/* <Caption level={4} color={colors.white}>
                      {status}
                    </Caption> */}
                  </VisibleAbove>
                  <VisibleBelow breakpoint='md'>
                    <Centered width={'100%'}>
                      <Caption level={3} color={colors.white}>
                        Business Hours
                      </Caption>
                    </Centered>
                    {/* <Caption level={4} color={colors.white}>
                      {status}
                    </Caption> */}
                  </VisibleBelow>
                </GridItem>
                <GridItem width={['100%', '100%', '40%', '40%']}>
                  <BusinessHours
                    locationDetails={locationDetails}
                    isLoading={loadingData}
                    firstTimeLoading={firstTimeLoading}
                  />
                </GridItem>
                <GridItem width={['100%', '100%', '30%', '30%']}>
                  <Centered>
                    <Caption level={3} color={colors.white}></Caption>
                  </Centered>
                </GridItem>
              </Grid>
            </Panel>
          </Section>
        </Panel>

        <Section dimension='fluid' variant='default'>
          <Panel>
            <Panel maxWidth='90%' width='1000px' margin='0 auto'>
              <Grid>
                <GridItem width={['100%', '100%', '100%', '100%']}>
                  <Caption level={2}>Our Menu</Caption>
                </GridItem>
                <GridItem width={['100%', '100%', '100%', '100%']}>
                  <FoodMenu
                    menu={menu['Main']}
                    isLoading={loadingData}
                    firstTimeLoading={firstTimeLoading}
                    vertical
                    columnsQty={2}
                    displayModifiers={false}
                    showImages={true}
                  />
                </GridItem>
              </Grid>
            </Panel>
          </Panel>
        </Section>

        <Section dimension='fluid' noPadding>
          <AspectRatioBox ratio='FREE' minHeight='550px' centered backgroundImage={homeBgs[4]}>
            <Curtain opacity={0.1} backgroundColor='#000' mixBlendMode='unset' />
            <Panel padding='20px' maxWidth={['100%', '100%', '80%']} zIndex={2}>
              <a id='subscribe' />
              <Centered>
                <Caption level={2} noMargin textAlign='center' color={colors.white}>
                  <Uppercase>Get the latest news first</Uppercase>
                </Caption>
                <SubscribeForm />
              </Centered>
            </Panel>
          </AspectRatioBox>
        </Section>
      </HomeLayout>
    </Fragment>
  );
};

export default withProviders(IndexPage);
