import React, { memo, useState } from 'react';
import validator from 'email-validator';
import PropTypes from 'prop-types';
import {
  Alert,
  FormState,
  Form,
  FormFields,
  Input,
  Button,
  FormField,
  ErrorText,
  Flex,
  TextArea,
  Centered,
} from '@sdflc/ui';

import { createResult, submitForm } from '../../utils';
import { appSettings, colors } from '../../config';

const SubscribeForm = memo((props) => {
  const [valid, setValid] = useState(false);
  const [data, setData] = useState({ email: '' });
  const [submitResult, setSubmitResult] = useState(createResult());

  return (
    <FormState
      data={data}
      onSubmit={async (formData) => {
        setSubmitResult(createResult());

        const result = await submitForm(appSettings.subscribeFormId, formData);

        setSubmitResult(result);
      }}
      onChange={(data) => {
        setData({ email: data.email });
        setValid(validator.validate(data.email));
      }}
    >
      {({ data, handleChange, submit }) => {
        return (
          <Flex width='100%' mt={30} backgroundColor={colors.transparent} borderRadius={3} padding={3}>
            <Form margin='0'>
              {!submitResult.success && (
                <FormFields>
                  <FormField>
                    <Input
                      name='email'
                      onChange={handleChange}
                      value={data.email}
                      placeholder='Your email address'
                      hasError={!!submitResult.fields['email']}
                      RightSide={
                        <Button
                          name='Subscribe'
                          variant='info'
                          onClick={submit}
                          disabled={!valid}
                          dimension='large'
                          hidden={submitResult.success}
                        />
                      }
                    />
                    {submitResult.fields['email'] && <ErrorText>{submitResult.fields['email']}</ErrorText>}
                  </FormField>
                  {/* <FormField>
                    <TextArea
                      rows={5}
                      name='comment'
                      onChange={handleChange}
                      value={data.comment}
                      placeholder='Your opinion matters'
                      hasError={!!submitResult.fields['comment']}
                    />
                    {submitResult.fields['comment'] && <ErrorText>{submitResult.fields['comment']}</ErrorText>}
                  </FormField> */}
                </FormFields>
              )}
              {/* <FormFields>
                <FormField>
                  <Centered>
                    <Button
                      name='Subscribe'
                      variant='primary'
                      onClick={submit}
                      disabled={!valid}
                      dimension='large'
                      hidden={submitResult.success}
                    />
                  </Centered>
                </FormField>
              </FormFields> */}
              <FormFields>
                <FormField>
                  {submitResult.success && <Alert variant='success'>{submitResult.success}</Alert>}
                  {submitResult.error && <Alert variant='danger'>{submitResult.error}</Alert>}
                </FormField>
              </FormFields>
            </Form>
          </Flex>
        );
      }}
    </FormState>
  );
});

export { SubscribeForm };
